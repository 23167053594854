*, :after, :before {
  box-sizing: border-box;
}

:root {
  --color-text: #000;
  --color-bg: #3124c7;
  --color-link: #fff;
  --color-link-hover: #000;
  --color-label: #fff;
  --content-hover: #f3727e;
  --gap-horizontal: 20px;
  --gap-vertical: 5px;
  --time-anim: 1s;
  --blend-mode-1: none;
  --blend-mode-2: color-dodge;
  --blend-mode-3: luminosity;
  --blend-mode-4: none;
  --blend-mode-5: none;
  --blend-color-1: #f3727e;
  --blend-color-2: #f3727e;
  --blend-color-3: #3124c7;
  --blend-color-4: #3124c7;
  --blend-color-5: #f3727e;
  --rotate-time: .5s;
  --rotate-easing: cubic-bezier(.2, 1, .3, 1);
  --fade-out-time: .25s;
  --fade-in-time: .25s;
  --fade-out-easing: ease-out;
  --fade-in-easing: ease-out;
  font-size: 16px;
}

.demo-2 {
  --color-bg: #6d5c5b;
  --content-hover: #000;
}

body {
  color: var(--color-text);
  background-color: var(--color-bg);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  font-family: widescreen-ex, sans-serif;
  overflow-x: hidden;
}

.js .loading:before, .js .loading:after {
  content: "";
  z-index: 1000;
  position: fixed;
}

.js .loading:before {
  width: 100%;
  height: 100%;
  background: var(--color-bg);
  top: 0;
  left: 0;
}

.js .loading:after {
  width: 60px;
  height: 60px;
  opacity: .4;
  background: var(--color-link);
  border-radius: 50%;
  margin: -30px 0 0 -30px;
  animation: .7s linear infinite alternate forwards loaderAnim;
  top: 50%;
  left: 50%;
}

@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(.5, .5, 1);
  }
}

a {
  color: var(--color-link);
  cursor: pointer;
  outline: none;
  text-decoration: none;
}

a:hover {
  color: #fff;
  outline: none;
}

a:focus {
  background: #d3d3d3;
  outline: none;
}

a:focus:not(:focus-visible) {
  background: none;
}

a:focus-visible {
  background: none;
  outline: 2px solid red;
}

.unbutton {
  font: inherit;
  cursor: pointer;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
}

.unbutton:focus {
  outline: none;
}

.frame {
  text-transform: uppercase;
  padding: 1rem 2rem;
}

.frame__title-main {
  margin: 0;
  font-size: 1rem;
  font-weight: normal;
}

.frame__title-back {
  align-items: flex-end;
  margin-bottom: .15rem;
  display: flex;
  position: relative;
}

.frame__title-back span {
  display: none;
}

.frame__title-back svg {
  fill: var(--color-text);
}

.frame__title-prev {
  margin: 0 2rem 0 0;
}

.frame__demo {
  margin: 0 1rem 0 0;
}

.frame__demo--current {
  color: var(--color-link-hover);
}

.intro {
  margin-bottom: 2rem;
  padding: 10vh 2rem;
}

.intro__title {
  grid-area: title;
  margin: 0;
  font-family: widescreen-ex, sans-serif;
  font-size: clamp(2rem, 12vw, 10rem);
  font-weight: 800;
  line-height: 1;
}

.intro__title span {
  text-transform: uppercase;
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent;
  color: #0000;
  text-shadow: -10px 10px #000;
}

.intro__title em {
  text-transform: uppercase;
  -webkit-text-stroke: 1px #000;
  text-stroke: 1px #000;
  -webkit-text-fill-color: var(--color-bg);
  text-fill-color: var(--color-bg);
  color: var(--color-bg);
  margin: -2.5vw 0 0;
  font-family: park-lane, serif;
  font-size: clamp(2rem, 10vw, 10rem);
  font-style: normal;
  font-weight: 300;
  display: block;
}

.intro__description {
  max-width: 350px;
  grid-area: desc;
  margin: 1.75vw 0 0;
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 500;
}

.intro__button {
  border: 1px solid var(--color-text);
  background: var(--color-bg);
  text-transform: uppercase;
  border-radius: 1.25rem;
  grid-area: button;
  justify-self: end;
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  box-shadow: 1px 2px #000;
}

.intro__button:hover, .intro__button:focus {
  transform: translate3d(1px, 2px, 0);
  box-shadow: 0 0 #000;
}

.content {
  grid-gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  margin: 0 auto;
  display: grid;
}

@media only screen and (max-width: 600px) {
  .content {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

.item {
  aspect-ratio: .8;
  cursor: pointer;
  perspective: 1000px;
  border-radius: .9rem;
  grid-template-rows: 100%;
  grid-template-columns: 100%;
  margin: 0;
  display: grid;
  position: relative;
  overflow: hidden;
}

.demo-2 .item {
  overflow: visible;
}

.demo-2 .item--oh {
  overflow: hidden;
}

.item__img {
  opacity: 0;
  width: 100%;
  height: 100%;
  grid-area: 1 / 1 / -1 / -1;
  position: relative;
}

.item:hover .item__img {
  opacity: 1;
  transition: transform var(--rotate-time) var(--rotate-easing);
  transform: scale(1.2);
}

.glitch {
  place-items: center;
  display: grid;
}

.glitch__img {
  background-image: var(--img);
  image-rendering: pixelated;
  width: 100%;
  height: 100%;
  background-position: 50%;
  background-size: cover;
  background-color: var(--blend-color-1);
  background-blend-mode: var(--blend-mode-1);
  grid-area: 1 / 1 / -1 / -1;
  transform: translate3d(0, 0, 0);
}

.glitch__img:nth-child(2) {
  background-color: var(--blend-color-2);
  background-blend-mode: var(--blend-mode-2);
}

.glitch__img:nth-child(3) {
  background-color: var(--blend-color-3);
  background-blend-mode: var(--blend-mode-3);
}

.glitch__img:nth-child(4) {
  background-color: var(--blend-color-4);
  background-blend-mode: var(--blend-mode-4);
}

.glitch__img:nth-child(5) {
  background-color: var(--blend-color-5);
  background-blend-mode: var(--blend-mode-5);
}

.glitch__img:nth-child(n+2) {
  opacity: 0;
}

.item:hover .glitch__img:nth-child(n+2) {
  opacity: 1;
}

.item:hover .glitch__img:nth-child(2) {
  transform: translate3d(var(--gap-horizontal), 0, 0);
  animation: glitch-anim-1-horizontal var(--time-anim) infinite linear alternate;
}

.item:hover .glitch__img:nth-child(3) {
  transform: translate3d(calc(-1 * var(--gap-horizontal)), 0, 0);
  animation: glitch-anim-2-horizontal var(--time-anim) infinite linear alternate;
}

.item:hover .glitch__img:nth-child(4) {
  transform: translate3d(0, calc(-1 * var(--gap-vertical)), 0) scale3d(-1, -1, 1);
  animation: glitch-anim-3-horizontal var(--time-anim) infinite linear alternate;
}

.item:hover .glitch__img:nth-child(5) {
  animation: .5s step-end infinite glitch-anim-flash;
}

@keyframes glitch-anim-1-horizontal {
  0% {
    clip-path: polygon(0 2%, 100% 2%, 100% 5%, 0 5%);
  }

  10% {
    clip-path: polygon(0 15%, 100% 15%, 100% 15%, 0 15%);
  }

  20% {
    clip-path: polygon(0 10%, 100% 10%, 100% 20%, 0 20%);
  }

  30% {
    clip-path: polygon(0 1%, 100% 1%, 100% 2%, 0 2%);
  }

  40% {
    clip-path: polygon(0 33%, 100% 33%, 100% 33%, 0 33%);
  }

  50% {
    clip-path: polygon(0 44%, 100% 44%, 100% 44%, 0 44%);
  }

  60% {
    clip-path: polygon(0 50%, 100% 50%, 100% 20%, 0 20%);
  }

  70% {
    clip-path: polygon(0 70%, 100% 70%, 100% 70%, 0 70%);
  }

  80% {
    clip-path: polygon(0 80%, 100% 80%, 100% 80%, 0 80%);
  }

  90% {
    clip-path: polygon(0 50%, 100% 50%, 100% 55%, 0 55%);
  }

  100% {
    clip-path: polygon(0 70%, 100% 70%, 100% 80%, 0 80%);
  }
}

@keyframes glitch-anim-2-horizontal {
  0% {
    clip-path: polygon(0 25%, 100% 25%, 100% 30%, 0 30%);
  }

  15% {
    clip-path: polygon(0 3%, 100% 3%, 100% 3%, 0 3%);
  }

  22% {
    clip-path: polygon(0 5%, 100% 5%, 100% 20%, 0 20%);
  }

  31% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }

  45% {
    clip-path: polygon(0 40%, 100% 40%, 100% 40%, 0 40%);
  }

  51% {
    clip-path: polygon(0 52%, 100% 52%, 100% 59%, 0 59%);
  }

  63% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }

  76% {
    clip-path: polygon(0 75%, 100% 75%, 100% 75%, 0 75%);
  }

  81% {
    clip-path: polygon(0 65%, 100% 65%, 100% 40%, 0 40%);
  }

  94% {
    clip-path: polygon(0 45%, 100% 45%, 100% 50%, 0 50%);
  }

  100% {
    clip-path: polygon(0 14%, 100% 14%, 100% 33%, 0 33%);
  }
}

@keyframes glitch-anim-3-horizontal {
  0% {
    clip-path: polygon(0 1%, 100% 1%, 100% 3%, 0 3%);
  }

  5% {
    clip-path: polygon(0 10%, 100% 10%, 100% 9%, 0 9%);
  }

  10% {
    clip-path: polygon(0 5%, 100% 5%, 100% 6%, 0 6%);
  }

  25% {
    clip-path: polygon(0 20%, 100% 20%, 100% 20%, 0 20%);
  }

  27% {
    clip-path: polygon(0 10%, 100% 10%, 100% 10%, 0 10%);
  }

  30% {
    clip-path: polygon(0 30%, 100% 30%, 100% 25%, 0 25%);
  }

  33% {
    clip-path: polygon(0 15%, 100% 15%, 100% 16%, 0 16%);
  }

  37% {
    clip-path: polygon(0 40%, 100% 40%, 100% 39%, 0 39%);
  }

  40% {
    clip-path: polygon(0 20%, 100% 20%, 100% 21%, 0 21%);
  }

  45% {
    clip-path: polygon(0 60%, 100% 60%, 100% 55%, 0 55%);
  }

  50% {
    clip-path: polygon(0 30%, 100% 30%, 100% 31%, 0 31%);
  }

  53% {
    clip-path: polygon(0 70%, 100% 70%, 100% 69%, 0 69%);
  }

  57% {
    clip-path: polygon(0 40%, 100% 40%, 100% 41%, 0 41%);
  }

  60% {
    clip-path: polygon(0 80%, 100% 80%, 100% 75%, 0 75%);
  }

  65% {
    clip-path: polygon(0 50%, 100% 50%, 100% 51%, 0 51%);
  }

  70% {
    clip-path: polygon(0 90%, 100% 90%, 100% 90%, 0 90%);
  }

  73% {
    clip-path: polygon(0 60%, 100% 60%, 100% 60%, 0 60%);
  }

  80% {
    clip-path: polygon(0 100%, 100% 100%, 100% 99%, 0 99%);
  }

  100% {
    clip-path: polygon(0 70%, 100% 70%, 100% 71%, 0 71%);
  }
}

@keyframes glitch-anim-1-vertical {
  0% {
    clip-path: polygon(2% 0, 5% 0, 5% 100%, 2% 100%);
  }

  10% {
    clip-path: polygon(15% 0, 15% 0, 15% 100%, 15% 100%);
  }

  20% {
    clip-path: polygon(10% 0, 20% 0, 20% 100%, 10% 100%);
  }

  30% {
    clip-path: polygon(1% 0, 2% 0, 2% 100%, 1% 100%);
  }

  40% {
    clip-path: polygon(33% 0, 33% 0, 33% 100%, 33% 100%);
  }

  50% {
    clip-path: polygon(44% 0, 44% 0, 44% 100%, 44% 100%);
  }

  60% {
    clip-path: polygon(50% 0, 20% 0, 20% 100%, 50% 100%);
  }

  70% {
    -webkit-clip-path: polygon(70% 0, 70% 0, 70% 100% 70%, 70% 100%);
    clip-path: polygon(70% 0, 70% 0, 70% 100% 70%, 70% 100%);
  }

  80% {
    -webkit-clip-path: polygon(80% 0, 80% 0, 80% 100% 80%, 80% 100%);
    clip-path: polygon(80% 0, 80% 0, 80% 100% 80%, 80% 100%);
  }

  90% {
    clip-path: polygon(50% 0, 55% 0, 55% 100%, 50% 100%);
  }

  100% {
    clip-path: polygon(70% 0, 80% 0, 80% 100%, 70% 100%);
  }
}

@keyframes glitch-anim-2-vertical {
  0% {
    clip-path: polygon(25% 0, 30% 0, 30% 100%, 25% 100%);
  }

  15% {
    clip-path: polygon(3% 0, 3% 0, 3% 100%, 3% 100%);
  }

  22% {
    clip-path: polygon(5% 0, 20% 0, 20% 100%, 5% 100%);
  }

  31% {
    clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
  }

  45% {
    clip-path: polygon(40% 0, 40% 0, 40% 100%, 40% 100%);
  }

  51% {
    clip-path: polygon(52% 0, 59% 0, 59% 100%, 52% 100%);
  }

  63% {
    clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
  }

  76% {
    clip-path: polygon(75% 0, 75% 0, 75% 100%, 75% 100%);
  }

  81% {
    clip-path: polygon(65% 0, 40% 0, 40% 100%, 65% 100%);
  }

  94% {
    clip-path: polygon(45% 0, 50% 0, 50% 100%, 45% 100%);
  }

  100% {
    clip-path: polygon(14% 0, 33% 0, 33% 100%, 14% 100%);
  }
}

@keyframes glitch-anim-3-vertical {
  0% {
    clip-path: polygon(1% 0, 3% 0, 3% 100%, 1% 100%);
  }

  5% {
    clip-path: polygon(10% 0, 9% 0, 9% 100%, 10% 100%);
  }

  10% {
    -webkit-clip-path: polygon(5% 0, 6% 0 6% 100%, 5% 100%);
    clip-path: polygon(5% 0, 6% 0 6% 100%, 5% 100%);
  }

  25% {
    clip-path: polygon(20% 0, 20% 0, 20% 100%, 20% 100%);
  }

  27% {
    clip-path: polygon(10% 0, 10% 0, 10% 100%, 10% 100%);
  }

  30% {
    clip-path: polygon(30% 0, 25% 0, 25% 100%, 30% 100%);
  }

  33% {
    clip-path: polygon(15% 0, 16% 0, 16% 100%, 15% 100%);
  }

  37% {
    clip-path: polygon(40% 0, 39% 0, 39% 100%, 40% 100%);
  }

  40% {
    clip-path: polygon(20% 0, 21% 0, 21% 100%, 20% 100%);
  }

  45% {
    clip-path: polygon(60% 0, 55% 0, 55% 100%, 60% 100%);
  }

  50% {
    clip-path: polygon(30% 0, 31% 0, 31% 100%, 30% 100%);
  }

  53% {
    clip-path: polygon(70% 0, 69% 0, 69% 100%, 70% 100%);
  }

  57% {
    clip-path: polygon(40% 0, 41% 0, 41% 100%, 40% 100%);
  }

  60% {
    clip-path: polygon(80% 0, 75% 0, 75% 100%, 80% 100%);
  }

  65% {
    clip-path: polygon(50% 0, 51% 0, 51% 100%, 50% 100%);
  }

  70% {
    clip-path: polygon(90% 0, 90% 0, 90% 100%, 90% 100%);
  }

  73% {
    clip-path: polygon(60% 0, 60% 0, 60% 100%, 60% 100%);
  }

  80% {
    clip-path: polygon(100% 0, 99% 0, 99% 100%, 100% 100%);
  }

  100% {
    clip-path: polygon(70% 0, 71% 0, 71% 100%, 70% 100%);
  }
}

@keyframes glitch-anim-flash {
  0% {
    opacity: .2;
    transform: translate3d(var(--gap-horizontal), var(--gap-vertical), 0);
  }

  33%, 100% {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }
}

.item__cover {
  transition: opacity var(--fade-in-time) var(--fade-in-easing);
  pointer-events: none;
  background-position: 50%;
  background-size: cover;
  grid-area: 1 / 1 / -1 / -1;
  position: relative;
}

.item__cover:after {
  content: "";
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #363ac761, #0003);
  position: absolute;
}

.item:hover .item__cover {
  opacity: 0;
  transition: opacity var(--fade-out-time) var(--fade-out-easing);
}

.item__content {
  pointer-events: none;
  color: var(--color-label);
  flex-direction: column;
  grid-area: 1 / 1 / -1 / -1;
  padding: 1.5rem;
  display: flex;
  position: relative;
}

.item__content-title {
  margin: 0;
  font-family: park-lane, serif;
  font-size: clamp(3rem, 4vw, 5rem);
  font-style: normal;
  font-weight: 300;
  line-height: 1;
}

.item:hover .item__content-title, .item:hover .item__content-label {
  color: var(--content-hover);
}

.item__content-label {
  margin: auto 0 0;
}

blockquote {
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-weight: 500;
}

@media screen and (min-width: 53em) {
  .frame {
    text-align: center;
    grid-row-gap: 1rem;
    grid-template: "title sponsor"
                   "demos demos"
                   / auto auto;
    align-content: space-between;
    display: grid;
  }

  .frame__title {
    grid-area: title;
    grid-template-columns: auto auto 1fr;
    grid-auto-flow: column;
    place-items: start flex-start;
    display: grid;
  }

  .frame__demos {
    grid-area: demos;
    justify-self: end;
  }

  .frame__demo {
    margin: 0 0 0 1rem;
  }

  .intro {
    grid-column-gap: 1rem;
    text-align: right;
    grid-template-areas: "title desc"
                         "title button";
    justify-content: space-between;
    align-items: start;
    display: grid;
  }
}

.brand-purple {
  color: #d022ed;
}

.bg-brand-purple {
  background-color: #d022ed;
}

/*# sourceMappingURL=index.9ee53c53.css.map */
